/*-------------------------
   Header Styles
-------------------------*/
.axil-header {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 4;
  .header-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-logo {
      a {
        display: block;
      }
      .dark-version-logo {
        display: none;
      }
      .sticky-logo {
        display: none;
      }
      img {
        @media #{$md-layout} {
          width: 150px;
        }
      }
    }
    .header-main-nav {
      flex: 1;
      margin: 0 55px 0 50px;
      @media #{$smlg-device} {
        margin: 0 30px;
      }
      @media #{$md-layout} {
        margin: 0;
      }
    }
  }
  &.header-style-1 {
    .axil-mainmenu {
      &.axil-sticky {
        .header-navbar {
          .header-logo {
            .light-version-logo {
              display: none;
            }
            .sticky-logo {
              display: block;
            }
          }
        }
      }
    }
  }
  &.header-style-2 {
    .axil-mainmenu {
      padding: 50px 80px;
      @media #{$laptop-device} {
        padding: 35px 15px;
      }
      @media #{$smlg-device} {
        padding: 35px 15px;
      }
      @media #{$large-mobile} {
        padding: 30px 0;
      }
      &.axil-sticky {
        padding: 15px 80px;
        @media #{$laptop-device} {
          padding: 15px;
        }
        @media #{$smlg-device} {
          padding: 15px;
        }
        @media #{$large-mobile} {
          padding: 15px 0;
        }
      }
      .mainmenu {
        justify-content: center;
      }
    }
  }
  &.header-style-3 {
    .axil-mainmenu {
      &.axil-sticky {
        .header-navbar {
          .header-logo {
            .light-version-logo {
              display: none;
            }
            .sticky-logo {
              display: block;
            }
          }
        }
      }
    }
    .header-action {
      .sidemenu-btn {
        .btn-wrap {
          background-color: var(--color-light);
        }
      }
    }
  }
  &.header-style-4 {
    @media only screen and (max-width: 1299px) {
      .header-main-nav {
        margin: 0 15px;
      }
    }
    @media #{$md-layout} {
      .header-main-nav {
        margin: 0;
      }
    }
    .mainmenu-nav {
      @media #{$md-layout} {
        background-color: white;
      }
      .mainmenu {
        .menu-item-has-children {
          .axil-submenu {
            background-color: white;
            li {
              a {
                color: var(--color-light);
                &:hover {
                  color: var(--color-primary);
                }
                &.active {
                  color: var(--color-primary);
                }
              }
            }
          }
        }
      }
    }
    .mobile-nav-header {
      border-color: var(--color-gray-4);
      .mobile-nav-logo {
        .light-mode {
          display: none;
        }
        .dark-mode {
          display: block;
        }
      }
      .mobile-menu-close {
        background-color: var(--color-white);
        color: var(--color-black);
        border: none;
      }
    }

    .axil-mainmenu {
      &.axil-sticky {
        background-color: var(--color-dark);
      }
    }
    .mainmenu {
      li {
        @media #{$smlg-device} {
          margin: 0 10px;
        }
        @media #{$md-layout} {
          border-color: var(--color-gray-4);
        }
        a {
          color: var(--color-light);
          &::before {
            background-color: var(--color-white);
          }
          &::after {
            color: var(--color-gray-4);
          }
        }
      }
    }
    .header-action {
      .header-btn {
        .axil-btn {
          display: block;
        }
      }
    }
  }
}

.axil-mainmenu {
  padding: 50px 0;
  transition: var(--transition);
  @media #{$laptop-device} {
    padding: 35px 0;
  }
  @media #{$smlg-device} {
    padding: 30px 0;
  }
  &.axil-sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background-color: var(--color-white);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 0;
    animation: headerSlideDown 0.8s ease forwards;
    .header-action {
      .sidemenu-btn {
        .btn-wrap {
          background-color: var(--color-body);
          span {
            background-color: var(--color-white);
          }
        }
      }
      .social-icon-list {
        li {
          a {
            color: var(--color-dark);
            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }
}

// Main Nav

.mainmenu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    margin: 0 24px;
    @media #{$smlg-device} {
      margin: 0 11px;
    }
    > a {
      color: var(--color-body);
      font-weight: 500;
      font-size: var(--font-body-2);
      font-family: var(--font-primary);
      line-height: 60px;
      height: 60px;
      display: block;
      position: relative;
      transition: var(--transition);
      &::before {
        content: "";
        height: 2px;
        width: 0;
        background-color: var(--color-primary);
        position: absolute;
        bottom: 12px;
        left: 0;
        opacity: 0;
        transition: 0.5s;
      }
      &:hover {
        color: var(--color-primary);
        &::before {
          opacity: 1;
          width: 100%;
        }
      }
      &.active {
        color: var(--color-primary);
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }
  > .menu-item-has-children {
    position: relative;
    > a {
      position: relative;
      svg {
        margin-left: 2px;
        font-size: 14px;
      }
    }
    .axil-submenu {
      position: absolute;
      top: 100%;
      left: 20px;
      background: #ffffff;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      min-width: 250px;
      padding: 15px 10px;
      border-radius: 4px;
      transition: var(--transition);
      list-style: none;
      margin: 0;
      box-shadow: 0 13px 48px 0 rgba(0, 0, 0, 0.15);
      li {
        margin: 0;
        transition: var(--transition);
        opacity: 0;
        transform: translateX(20px);
        a {
          position: relative;
          font-size: 14px;
          text-transform: capitalize;
          color: var(--color-body);
          font-weight: 500;
          padding: 7px 15px;
          border-radius: 4px;
          display: block;
          transition: var(--transition);
          &::after {
            content: "";
            height: 1px;
            width: 0;
            background-color: var(--color-primary);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            transition: var(--transition);
          }
          &:hover {
            color: var(--color-primary);
            &::after {
              width: 100%;
            }
          }
          &.active {
            color: var(--color-primary);
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
    &:hover {
      .axil-submenu {
        opacity: 1;
        visibility: visible;
        z-index: 9;
        left: 0;
        li {
          opacity: 1;
          transform: translateX(0);
          &:nth-child(1n) {
            transition-delay: 0.1s;
          }
          &:nth-child(2n) {
            transition-delay: 0.2s;
          }
          &:nth-child(3n) {
            transition-delay: 0.3s;
          }
          &:nth-child(4n) {
            transition-delay: 0.4s;
          }
          &:nth-child(5n) {
            transition-delay: 0.5s;
          }
          &:nth-child(6n) {
            transition-delay: 0.6s;
          }
          &:nth-child(7n) {
            transition-delay: 0.7s;
          }
          &:nth-child(8n) {
            transition-delay: 0.8s;
          }
          &:nth-child(9n) {
            transition-delay: 0.8s;
          }
          &:nth-child(10n) {
            transition-delay: 0.8s;
          }
          &:nth-child(11n) {
            transition-delay: 0.8s;
          }
          &:nth-child(12n) {
            transition-delay: 0.8s;
          }
        }
      }
    }
  }
}

.section-heading-left {
  display: block;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .section-heading-left {
    padding-top: 0rem;
    display: block;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 992px) {
  .shift-title {
    text-align: center;
  }
}

.richard-section {
  margin-top: 100px;
}
@media only screen and (max-width: 992px) {
  .richard-section {
    margin-top: 50px;
  }
}
// Header Action
.about-hero {
  margin-top: 2rem;
}

.header-action {
  > ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > li {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .sidemenu-btn {
    margin-right: 0;
    .btn-wrap {
      height: 60px;
      width: 60px;
      background-color: var(--color-light);
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: var(--transition);
      @media #{$md-layout} {
        height: 50px;
        width: 50px;
      }
      span {
        height: 2px;
        width: 20px;
        background-color: var(--color-dark);
        margin-bottom: 4px;
        border-radius: 10px;
        transition: var(--transition);
        &:last-child {
          margin-bottom: 0;
          width: 10px;
          margin-right: -10px;
        }
      }
      &:focus {
        box-shadow: none;
      }
      &:hover {
        span {
          &:last-child {
            width: 20px;
            margin-right: 0;
          }
          &:first-child {
            width: 10px;
            margin-right: -10px;
          }
        }
      }
      &.btn-dark {
        background-color: var(--color-body);
        span {
          background-color: var(--color-white);
        }
      }
    }
  }
  .header-social-link {
    @media #{$large-mobile} {
      display: none;
    }
  }
  .social-icon-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -15px;
    li {
      margin: 15px;
      a {
        font-size: 20px;
        color: var(--color-body);
        @media #{$md-layout} {
          color: var(--color-dark);
        }
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
  .header-btn {
    margin-right: 0;
    @media only screen and (max-width: 1399px) {
      .axil-btn {
        padding: 15px 40px;
      }
    }
    @media #{$lg-layout} {
      .axil-btn {
        padding: 15px 25px;
      }
    }
    @media #{$md-layout} {
      margin-right: 30px;
    }

    @media #{$large-mobile} {
      display: none;
    }
  }
  .mobile-menu-btn {
    @media only screen and (min-width: 992px) {
      display: none;
    }
    .btn-wrap {
      height: 50px;
      width: 50px;
      margin-left: 5px;
    }
  }
  .my_switcher {
    margin-left: 20px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    line-height: 52px;
    padding: 0;
    text-align: center;
    @media #{$sm-layout} {
      margin-left: 15px;
    }
    button {
      width: 100%;
      height: 100%;
      line-height: 1;
      span {
        width: 100%;
        line-height: 34px;
        @media #{$sm-layout} {
          line-height: 24px;
        }
      }
    }
  }
}
.center-spinner {
  display: grid;
  place-items: center;
  min-height: 100vh;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.thicker {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 5px solid #138384;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #C3C7C7;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text-change {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.richard-padding{
  margin-top: 200px;

}
@media only screen and (max-width: 991px) {
 .richard-padding{
  margin-top: 50px;

}

}

// Offcanvas Menu
.header-offcanvasmenu {
  margin-top: 5.5%;
  top: 20%;
  left: 70%;
  
  position: fixed;
  height: 45vh;
  border-radius: 50px;
  background-color: var(--color-white);
  transition: all 0.34s cubic-bezier(0.77, 0.2, 0.05, 1);
  @media #{$smlg-device} {
    margin-top: 2rem;
    width: 50vw;
    height: auto;
  }
  .offcanvas-header {
    padding: 30px 50px 0 50px;
    display: flex;
   
    justify-content: center;
    align-items: center;
    @media #{$laptop-device} {
      padding: 30px;
    }
    @media #{$smlg-device} {
      padding: 30px;
    }
    @media #{$sm-layout} {
      padding: 30px 30px 0 30px;
    }
  }
  .offcanvas-body {
    overflow-y: hidden;
    overflow-x: hidden;
    
    padding: 0px 0px 0px 0px;
    @media #{$laptop-device} {
      padding: 10px;
    }
    @media #{$smlg-device} {
      padding: 30px;
    }
    @media #{$sm-layout} {
      padding: 30px;
    }
   
    .main-navigation {
      margin: -30px 0;
      margin-top: -45px;
      @media #{$md-layout} {
        margin-bottom: 40px;
      }
      @media #{$sm-layout} {
        margin: -20px 0;
        margin-bottom: 40px;
      }
      li {
        margin: 30px 0;
        @media #{$sm-layout} {
          margin: 20px 0;
        }
        a {
          color: var(--color-dark);
          font-size: 36px;
          font-weight: 500;
          @media #{$smlg-device} {
            font-size: 24px;
          }
          @media #{$sm-layout} {
            font-size: 20px;
          }
          &:hover {
            color: var(--color-primary);
          }
          &.active {
            color: var(--color-primary);
          }
        }
      }
    }
    .contact-inner {
      .title {
        font-size: 18px;
        line-height: 1;
        color: var(--color-dark);
        font-weight: 500;
        display: block;
        margin-bottom: 15px;
        @media #{$smlg-device} {
          font-size: 16px;
        }
      }
      .address {
        margin-bottom: 40px;
        @media #{$md-layout} {
          margin-bottom: 25px;
        }
        a {
          color: var(--color-body);
          display: block;
          margin-bottom: 10px;
          i {
            margin-right: 20px;
          }
          svg {
            margin-right: 15px;
          }
        }
        p {
          margin-bottom: 0;
        }
      }
      .social-share {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -15px -6px;
        li {
          margin: 15px 6px;
          a {
            color: var(--color-white);
            font-size: 16px;
            text-align: center;
            width: 50px;
            height: 50px;
            line-height: 50px;
            display: block;
            border-radius: 100%;
            background-color: var(--color-primary);
            text-align: center;
            &:hover {
              background-color: var(--color-dark);
            }
          }
        }
      }
    }
  }
}

// Mobile Menu

.mobilemenu-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 10;
  transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  visibility: hidden;
  opacity: 0;
  &:after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 300px;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: var(--transition);
  }
  .mobilemenu-inner {
    width: 300px;
    background-color: var(--color-white);
    padding: 0 10px 50px;
    overflow-y: auto;
    height: 100%;
  }
  .mainmenu-nav {
    display: block;
  }
  .mobilemenu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
    padding: 20px 0;
    border-bottom: 1px solid var(--color-fog);
    @media only screen and (min-width: 992px) {
      display: none;
    }
    .mobile-nav-logo {
      width: 150px;
      line-height: 1;
      .dark-mode {
        display: none;
      }
    }
    .mobile-menu-close {
      background-color: transparent;
      height: 30px;
      width: 30px;
      border: 2px solid var(--color-gray-4);
      border-radius: 10px;
      color: var(--color-dark);
      font-size: 14px;
      transition: var(--transition);
      line-height: 1;
      margin: 0;
      padding: 0;
      &:hover {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: var(--color-white);
      }
    }
  }

  .axil-mainmenu {
    &.axil-sticky {
      animation: none;
    }
  }
  .mainmenu-nav {
    .mainmenu {
      display: block;
      height: 100%;
      overflow: auto;
      > li {
        border-bottom: 1px solid var(--color-fog);
        > a {
          height: 50px;
          line-height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &::before {
            display: none;
          }
        }
      }
      .menu-item-has-children {
        .axil-submenu {
          display: none;
          position: initial;
          visibility: visible;
          opacity: 1;
          min-width: 100%;
          box-shadow: none;
          padding: 0 0 10px;
          transition: initial;
          li {
            opacity: 1;
            transform: translateX(0);
            a {
              padding: 7px 10px;
              &::after {
                display: none;
              }
            }
          }
          &.active {
            display: block;
          }
        }
        &.menu-item-open {
          .axil-submenu {
            display: block;
          }
        }
      }
    }
  }

  .mobilemenu-body {
    padding: 0;
  }
  &.show {
    left: 0;
    visibility: visible;
    opacity: 1;
    &:after {
      visibility: visible;
      opacity: 1;
    }
  }
}
.farmless {
  
}
.emchat {
  width: 100%;
  margin-top: -20px;
  margin-left: -10px;
}
.academy{
  margin-left: 13px;
}
@media only screen and (max-width: 991px) {
  .farmless {
    width: "150px";
    margin-top: 0px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .mainmenu-nav {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-offcanvasmenu {
    margin-top: 30%;
    top: 20%;
    left: 2%;
    position: fixed;
    width: 60%;
    height: 50vh;
    background-color: var(--color-white);
    transition: all 0.34s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
}

/// Keyfram Animation
@keyframes headerSlideDown {
  0% {
    margin-top: -20px;
    opacity: 0;
  }
  30% {
    margin-top: -10px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

